import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "../../../../../../../components";
import { RupiahConvert } from "../../../../../../../utilities";

const TableListTPBOP = ({ dataSelectTPBOP, dataTPBOP, setDataTPBOP }) => {
  const { id } = useParams();
  const [processedData, setProcessedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "danger",
  });

  let lastUrutanItem = 1;
  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  let subTotal = 0;

  useEffect(() => {
    setSelectedData({});
    return () => {};
  }, [dataSelectTPBOP]);

  const ListDataTable = ({ index, val, total, setProcessedData, setModalConfig }) => {
    return (
      <tr key={index}>
        <td style={tableStyling} className="px-2">
          {val.kode_item_bahan}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_item}
        </td>
        <td style={tableStyling} className="text-right">
          {val.qty}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_satuan}
        </td>
        <td style={tableStyling} className="text-right">
          {val.unit_price && RupiahConvert(val.unit_price.toString()).detail}
        </td>
        <td style={tableStyling} className="text-right">
          {val.konstanta}
        </td>
        <td style={tableStyling} className="text-right">
          {RupiahConvert(total.toString()).detail}
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="px-2 pb-2 mt-3">
        <b>List Item Biaya Overhead</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <div>
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center">
            <tr>
              <th className="align-middle p-2" style={tableStyling}>
                Kode Item
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Item Overhead
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Qty
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Unit
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Unit Price (Rp)
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Konst.
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Total Price (Rp)
              </th>
            </tr>
          </thead>
          <tbody>
            {dataTPBOP.map((val, index) => {
              const total = parseInt(
                parseFloat(val.qty).toFixed(2) *
                  parseInt(val.unit_price) *
                  parseFloat(val.konstanta).toFixed(2)
              );
              const lastItem = parseInt(val.urutan_item) ? parseInt(val.urutan_item) + 1 : 1;
              lastUrutanItem = lastItem;
              subTotal = parseInt(subTotal) + parseInt(total);

              return (
                <ListDataTable
                  index={index}
                  val={val}
                  total={total}
                  editedData={editedData}
                  setEditedData={setEditedData}
                  setProcessedData={setProcessedData}
                  setModalConfig={setModalConfig}
                />
              );
            })}
            <tr>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total Price : </b>
              </td>
              <td className="text-right" style={tableStyling}>
                <b>{RupiahConvert(subTotal.toString()).detail}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableListTPBOP;
