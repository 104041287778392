import React from "react";
import { Table, THead, TBody, Tr, ThFixed, TdFixed, Th, Td } from "components";
import { RupiahConvert, DateConvert } from "utilities";

const TableSection = ({ data }) => {
  let dataTable = data ?? [];
  const totalKredit = () => {
    const total = dataTable.reduce((prev, { nilai }) => {
      const sum = parseFloat(prev) + parseFloat(nilai);
      return sum;
    }, 0);
    return total;
  };
  const DataTable = () => (
    <Table>
      <THead>
        <Tr>
          <ThFixed>No</ThFixed>
          <ThFixed>Tanggal</ThFixed>
          <ThFixed>No. Bukti</ThFixed>
          <Th>Keterangan</Th>
          <Th>Nilai</Th>
        </Tr>
      </THead>
      <TBody>
        {dataTable.map((val, index) => (
          <Tr>
            <TdFixed>{index + 1}</TdFixed>
            <TdFixed>{DateConvert(new Date(val.tanggal)).defaultDMY ?? "-"}</TdFixed>
            <TdFixed>{val.no_bukti ?? "-"}</TdFixed>
            <Td>{val.keterangan ?? "-"}</Td>
            <Td textRight>
              <div className="text-nowrap">
                {RupiahConvert(parseInt(val.nilai).toString()).detail ?? "-"}
              </div>
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td colSpan={4} textRight>
            <div style={{ fontWeight: "bold", fontSize: 14 }} className="text-nowrap">
              Total Saldo
            </div>
          </Td>
          <Td textRight>
            <div style={{ fontWeight: "bold", fontSize: 14 }} className="text-nowrap">
              {RupiahConvert(parseInt(totalKredit()).toString()).detail}
            </div>
          </Td>
        </Tr>
      </TBody>
    </Table>
  );
  return (
    <>
      <hr />
      <div style={{ fontWeight: "bold" }} className="mb-2 mt-2">
        Item Invoice
      </div>
      <DataTable />
    </>
  );
};

export default TableSection;
