import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Th,
  Tr,
  Td,
  TBody,
  CRUDLayout,
  TdFixed,
  ThFixed,
  THead,
} from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { InfoSection, TableSection } from "./Section";
import { InvoiceGCApi } from "api";

const DetailPPA = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_bukti, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataSIA, setDataSIA] = useState({});
  const [approveStatus, setApproveStatus] = useState("V");
  const [dataApproval, setDataApproval] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([InvoiceGCApi.getSingle({ no_bukti: no_bukti, id_voucher: id })])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior ? data.data.data.behavior : "V";
          setDataSIA(detail);
          setDataApproval(approval ?? []);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data Tidak Ditemukan!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  console.log(dataApproval);

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? "Approve"
                : modalConfig.type === "VER"
                ? "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP" || modalConfig.type === "VER"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Pembayaran Invoice</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval
              .filter((res) => res.status_approval !== "PEN")
              .map((val, index) => {
                return (
                  <InfoItem
                    key={index}
                    title1={
                      val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2={"Catatan"}
                    value2={val.catatan ?? "-"}
                  />
                );
              })}
          </div>
          <hr />
          {/* HIDE INPUT TEXT AREA KETIKA TAB HISTORY */}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject Pembayaran Invoice</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: <span className="text-success">Approve Pembayaran Invoice</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject Pembayaran Invoice</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    {/* <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: <span className="text-warning">Revise Pembayaran Invoice</span>,
                          });
                        }
                        validateForm();
                      }}
                    /> */}
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "VER",
                            title: <span className="text-success">Approve Pembayaran Invoice</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    setNavbarTitle("Approval Pembayaran Invoice");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Pembayaran Invoice</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              {/* <DetailData /> */}
              <InfoSection data={dataSIA} />
              {console.log(dataSIA)}

              <TableSection data={dataSIA.detail} />
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const finalValues = {
            ...values,
            no_transaksi: dataSIA.no_bukti,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
          };

          InvoiceGCApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/pembayaran-invoice", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${
                    modalConfig.type == "VER"
                      ? "Verify"
                      : modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
              });
              setSubmitting(false);
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              validateForm={validateForm}
              dirty={dirty}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailPPA;
