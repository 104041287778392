import Services from "../../services";

class PengakuanHutangApi {
  // API SERVER
  get(params) {
    return Services.get("approval_invoice_ar/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_invoice_ar/history/list", { params });
  }

  getSingle(params) {
    return Services.get("approval_invoice_ar/detail", { params });
  }

  approve(data) {
    return Services.post("approval_invoice_ar/approve", data);
  }
}

export default new PengakuanHutangApi();
