import React, {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col,
  Modal
} from 'react-bootstrap'
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline
} from 'react-icons/io5'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {  
  Alert,
  SelectSearch,
  Input,
  ActionButton
} from 'components'
import {  
  RupiahConvert
} from 'utilities'
import AnalisaBarangJadiRAP from './AnalisaBarangJadiRAP'

const TableBarangJadi = ({dataBarangJadi, dataSelectJenisBarangJadi, dataSelectAnalisaBarangJadi, setDataBarangJadi}) => {
  const [processedData, setProcessedData] = useState({})
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: ''
  })
  
  const TableSection = () => {
    const generateTotal = () => {
        const grandTotal = dataBarangJadi.reduce((prevValue, currentValue) => {
          const checkQty = currentValue.qty_rap ? parseFloat(currentValue.qty_rap) : '0'
          const checkSatuan = currentValue.harga_satuan_rap ? parseInt(currentValue.harga_satuan_rap) : '0'
          const subTotal = parseInt(checkQty * checkSatuan)
          const total = parseInt(prevValue + subTotal)
          
          return total
        }, 0)

      return RupiahConvert(grandTotal.toString()).detail
    }

    return (
      <>
        <div className="py-2 p-1">
          <b>List Barang Jadi</b>
        </div>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({
            ...alertConfig,
            show: false
          })}
        />
        <table className="table table-sm table-bordered" style={{fontSize: '14px'}}>
          <thead className="text-center">
            <tr>
              <th style={{width: '100px'}}>Kode Barang Jadi</th>
              {/* <th style={{width: '100px'}}>Kode Referensi Analisa Barang Jadi</th> */}
              <th style={{width: '300px'}}>Barang Jadi</th>
              <th>Qty</th>
              <th>Satuan</th>
              <th>Harga Satuan</th>
              <th>Sub Total</th>
            </tr>
          </thead>
          <tbody>
            {dataBarangJadi?.map((val, index) => {
              const checkQty = val.qty_rap ? parseFloat(val.qty_rap) : '0'
              const checkSatuan = val.harga_satuan_rap ? parseInt(val.harga_satuan_rap) : '0'
              const subTotal = parseInt(checkQty * checkSatuan)

              return (
                <tr key={index}>
                  <td>{val.kode_item}</td>
                  {/* <td>{val.ref_uid ? val.ref_uid : '-'}</td> */}
                  <td>
                    <a href="#" onClick={e => {
                      e.preventDefault()
                      setProcessedData({
                        ...val,
                        index: index
                      })
                      setModalConfig({
                        show: true,
                        type: 'analisa',
                        title: (<span className="text-primary">Analisa Barang Jadi RAP</span>)
                      })
                    }}>
                      {val.nama_item}
                    </a>
                  </td>
                  <td>{val.qty_rap}</td>
                  <td>{val.nama_satuan}</td>
                  <td className="text-right">{RupiahConvert(parseInt(val.harga_satuan_rap).toString()).detail}</td>
                  <td className="text-right">{RupiahConvert(subTotal?.toString()).detail}</td>
                </tr>
              )
            })}
            <tr>
              <td colSpan={5} className="text-right">
                <b>Total :</b>
              </td>
              <td className="text-right">
                <b>{generateTotal()}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  const ModalSection = ({processedData, dataBarangJadi, setDataBarangJadi, alertConfig, setAlertConfig, modalConfig, setModalConfig}) => {
    const AnalisaBarangJadiModal = ({processedData, dataBarangJadi, setDataBarangJadi, alertConfig, setAlertConfig, modalConfig, setModalConfig}) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAP 
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      )
    }

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() => setModalConfig({
          ...modalConfig,
          show: false
        })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>
              {modalConfig.title}
            </small>
          </Modal.Title>
        </Modal.Header>
          <AnalisaBarangJadiModal 
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
      </Modal>
    )
  }

  return (
    <>
      <TableSection 
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
      /> 
      <ModalSection 
        processedData={processedData}
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />  
    </>
  )
}

export default TableBarangJadi
